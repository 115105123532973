/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  baseUri,
  createCategoryMappingsApi,
  createDataSourceCategoriesApi,
  createDictionariesApi as createDictionariesLegacyApi,
  createManufacturersApi,
  createProductCategoriesApi,
  createProductModelsApi,
  createProductTemplateImagesApi,
  createTemplateIdentifiersApi,
  createTemplatePriceApi,
} from '@r1-webui/legacyproducttemplatemanagement-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/company-catalog/${baseUri}`

export const productTemplateDictionariesLegacyApi = createDictionariesLegacyApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateManufacturersApi = createManufacturersApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateDataSourceCategoriesApi = createDataSourceCategoriesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateCategoryMappingsApi = createCategoryMappingsApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateIdentifiersApi = createTemplateIdentifiersApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateImagesApi = createProductTemplateImagesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplatePriceApi = createTemplatePriceApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateModelsApi = createProductModelsApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateCategoriesApi = createProductCategoriesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
