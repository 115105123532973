import { submit } from 'redux-form'
import * as R from 'ramda'
import { NotificationSystem } from '@r1/ui-kit'

import { createReducer, createTypes } from '../../redux/utils'
import { actions as reviewActions } from '../../modules/productTemplates/review'
import { actions as lockActions } from '../../modules/productTemplates/lock'
import { productManagementApi } from '../../api/productManagement'
import { replaceTemplateValues } from '../../utils/responseUtils.ts'
import { removeObjPropertyNested } from '../../utils/dataUtils'
import { productsApi } from '../../api/families/index.ts'
import { productTemplateApi, productTemplatePriceApi } from '../../api/productTemplate/index.ts'
import { submitImages } from './components/tabs/Images/module'
import { proceedError, showErrors, stripHtml } from './utils'

const checkWeight = weightObj => {
  if (!weightObj.amount) return null
  return weightObj
}

const checkDimension = dimensionObj => {
  if (!dimensionObj.height || !dimensionObj.length || !dimensionObj.width) return null
  return dimensionObj
}

const reviewReasonOptions = [
  { key: 'DoneComplete', value: 'Done / Complete' },
  { key: 'NoGoogleDataAvailable', value: 'No Google data available' },
  { key: 'Corrections', value: 'Corrections' },
  { key: 'NeedsToBeCompleted', value: 'Needs to be completed' },
  { key: 'RS5Template', value: 'RS5 Template' },
  { key: 'Jewelry', value: 'Jewelry' },
  { key: 'Other', value: 'Other' },
]

const validationCases = ['DoneComplete', 'ScrappingComplete', 'Jewelry']

const initialState = {
  isLoading: false,
  reviewInfo: null,
  reviewReasonOptions: [],
  version: null,
  lockStatus: null,
  review: null,
  needSubmitForReview: false,
  categoryId: null,
  familyId: null,
}

const types = createTypes(
  [
    'toggleLoading',
    'updateVersion',
    'loadReview',
    'loadReviewReasons',
    'setLockStatus',
    'setReviewState',
    'changeCategoryId',
    'changeFamilyId',
  ],
  'productTemplate',
)

export const reducer = createReducer(initialState, {
  [types.toggleLoading]: (state, { isLoading }) => ({ ...state, isLoading }),
  [types.updateVersion]: (state, { version }) => ({ ...state, version }),
  [types.loadReview]: (state, { reviewInfo }) => ({ ...state, reviewInfo }),
  [types.setLockStatus]: (state, { lockStatus }) => ({ ...state, lockStatus }),
  [types.loadReviewReasons]: (state, payload) => ({ ...state, ...payload }),
  [types.setReviewState]: (state, { needSubmitForReview, review }) => ({
    ...state,
    needSubmitForReview,
    review,
  }),
  [types.changeCategoryId]: (state, { categoryId }) => ({ ...state, categoryId }),
  [types.changeFamilyId]: (state, { familyId }) => ({ ...state, familyId }),
})

export function toggleLoading(isLoading) {
  return {
    type: types.toggleLoading,
    isLoading,
  }
}

export function updateVersion(version) {
  return {
    type: types.updateVersion,
    version,
  }
}

export function loadReview(productTemplateId) {
  return async dispatch => {
    const reviewInfo = await dispatch(reviewActions.fetchItem({ id: productTemplateId }))

    dispatch({
      type: types.loadReview,
      reviewInfo,
    })
  }
}

export function checkForLocking(productTemplateId) {
  return async dispatch => {
    let lockStatus = ''
    if (productTemplateId !== 'new') {
      lockStatus = await dispatch(lockActions.fetchItem({ id: productTemplateId }))
    }

    dispatch({
      type: types.setLockStatus,
      lockStatus,
    })
  }
}

export function loadReviewReasons() {
  return dispatch => {
    dispatch({
      type: types.loadReviewReasons,
      reviewReasonOptions,
    })
  }
}

export function setReviewState(needSubmitForReview, review) {
  return async dispatch => {
    dispatch({
      type: types.setReviewState,
      needSubmitForReview,
      review,
    })
  }
}

export function submitForReview(id) {
  return async (dispatch, getState, _api) => {
    const { version, review } = getState().productTemplate
    try {
      const newVersion = await productTemplateApi
        .sendToReview(
          { id },
          {
            version,
            reason: review ? review.reason : '',
            comment: review ? review.comment : '',
          },
        )
        .then(({ body, status }) => {
          if (status !== 200) {
            const errorData = body || { errors: ['Failed to submit product template for review'] }
            // eslint-disable-next-line no-throw-literal
            throw {
              response: { status, data: errorData },
            }
          }

          return body
        })
      await dispatch(updateVersion(newVersion))
      await dispatch(setReviewState(false, null))
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Success',
        message: 'Product template has been submitted for review.',
      })
    } catch (err) {
      showErrors(proceedError(err))
    }
  }
}

export function approveTemplate(productTemplateId) {
  return async (dispatch, getState, _api) => {
    const { version } = getState().productTemplate
    try {
      const newVersion = await productTemplateApi
        .approve({ id: productTemplateId }, { version })
        .then(({ body, status }) => {
          if (status !== 200) {
            const errorData = body || { errors: ['Failed to approve product template'] }
            // eslint-disable-next-line no-throw-literal
            throw {
              response: { status, data: errorData },
            }
          }

          return body
        })
      await dispatch(updateVersion(newVersion))
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Success',
        message: 'Product template was approved.',
      })
    } catch (err) {
      showErrors(proceedError(err))
    }
  }
}

export function submitAll({ productTemplateId, review, approve, saveAttributeValues }) {
  return async (dispatch, getState, _api) => {
    if (review) {
      dispatch(setReviewState(true, review))
    } else {
      dispatch(setReviewState(false, null))
    }

    const {
      productTemplate: { version, needSubmitForReview, review: currentReview },
      pricing: { priceBlock },
    } = getState()
    if (!priceBlock) return

    const getPrice = priceTitle => {
      const priceContainer = priceBlock.template.find(p => p.name === priceTitle)
      if (
        !priceContainer ||
        !priceContainer.price ||
        !priceContainer.price.amount ||
        priceContainer.price.amount.length === 0
      ) {
        return null
      }
      return { amount: priceContainer.price.amount, currency: priceContainer.price.currency }
    }

    let newVersion = null
    let errorMessages = []

    try {
      newVersion = await productTemplatePriceApi
        .saveRetailPrice(
          { id: productTemplateId },
          {
            version,
            RetailPrice: getPrice('Retail Price'),
            RetailPriceCAD: getPrice('Retail Price CAD'),
            anchorMSRP: getPrice('Anchor MSRP'),
          },
        )
        .then(({ body, status }) => {
          if (status !== 200) {
            const errorData = body || { errors: ['Failed to save pricing'] }

            // eslint-disable-next-line no-throw-literal
            throw {
              response: { status, data: errorData },
            }
          }

          return body
        })
    } catch (err) {
      newVersion = version
      errorMessages = errorMessages.concat(proceedError(err))
    }
    try {
      await dispatch(updateVersion(newVersion))
    } catch (err) {
      errorMessages = errorMessages.concat(proceedError(err))
    }
    try {
      await dispatch(submit('categoryMappingForm'))
    } catch (err) {
      errorMessages = errorMessages.concat(proceedError(err))
    }
    try {
      await dispatch(submitImages(productTemplateId))
    } catch (err) {
      errorMessages = errorMessages.concat(proceedError(err))
    }

    try {
      await dispatch(submit('otherSettingsForm'))
    } catch (err) {
      errorMessages = errorMessages.concat(proceedError(err))
    }

    try {
      const {
        productInfo: {
          shortDescription,
          longDescription,
          model: { id: modelId = null } = {},
          manufacturer: { id: manufacturerId = null } = {},
          ...productInfoOther
        },
        packaging: { packageDimension, productDimension, packageWeight, productWeight },
      } = removeObjPropertyNested(R.clone(getState().form.mainInfoForm.values), '$type')

      newVersion = await productTemplateApi
        .updateProductTemplate(
          { id: productTemplateId },
          {
            productInfo: {
              ...productInfoOther,
              modelId,
              manufacturerId,
              shortDescription: stripHtml(shortDescription) !== '' ? shortDescription : null,
              longDescription: stripHtml(longDescription) !== '' ? longDescription : null,
            },
            enableValidation:
              (needSubmitForReview &&
                currentReview &&
                validationCases.includes(currentReview.reason)) ||
              !!approve,
            packaging: {
              productWeight: checkWeight(productWeight),
              packageWeight: checkWeight(packageWeight),
              productDimension: checkDimension(productDimension),
              packageDimension: checkDimension(packageDimension),
            },
            version: newVersion,
          },
        )
        .then(({ body, status }) => {
          if (status !== 200) {
            const errorData = body || { errors: ['Failed to update product template'] }

            // eslint-disable-next-line no-throw-literal
            throw {
              response: { status, data: errorData },
            }
          }

          return body
        })
    } catch (err) {
      errorMessages = errorMessages.concat(proceedError(err))
    }

    try {
      await dispatch(updateVersion(newVersion))
    } catch (err) {
      errorMessages = errorMessages.concat(proceedError(err))
    }

    const assignFamily = async () => {
      const { familyId: initialFamilyId } = getState().form.mainInfoForm.initial.productInfo
      const { familyId } = getState().form.mainInfoForm.values.productInfo

      if (!familyId) {
        if (initialFamilyId) {
          errorMessages = errorMessages.concat({
            title: 'Error',
            message: "Family can't be removed",
          })
        }
        return
      }

      if (familyId === initialFamilyId) {
        return
      }

      const response = await productsApi.assignFamily(
        { productId: productTemplateId },
        { familyId },
      )

      switch (response.status) {
        case 200:
          return
        case 403: {
          errorMessages = errorMessages.concat({
            title: 'Error',
            message: `You don't have required permissions to assign Family`,
          })
          break
        }
        default:
          errorMessages = errorMessages.concat({
            title: 'Error',
            message: replaceTemplateValues(response.body),
          })
      }
    }

    try {
      await saveAttributeValues(productTemplateId)
      await assignFamily()
    } catch (err) {
      const processedError =
        err.response.status === 422
          ? { title: 'Error', message: 'Attributes form is filled in incorrectly' }
          : proceedError(err)

      errorMessages = errorMessages.concat(processedError)
    }

    if (review) {
      try {
        await dispatch(submitForReview(productTemplateId))
      } catch (err) {
        const a = proceedError(err)
        errorMessages = errorMessages.concat(a)
      }
    }

    if (!approve && !review && errorMessages.length === 0) {
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Success',
        message: 'Product template has been successfully submitted.',
      })
    } else if (errorMessages.length > 0) {
      showErrors(errorMessages)
    }

    if (approve && errorMessages.length === 0) {
      await dispatch(approveTemplate(productTemplateId))
    }
  }
}

export function createNewTemplate(navigate, { type, value }) {
  return async (_dispatch, _getState, _api) => {
    await productTemplateApi.create({ type, value }).then(({ body, status }) => {
      if (status !== 200) {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Error',
          message: 'Failed to create new product template',
        })
      } else {
        navigate(`/r1/producttemplate/${body}`)
      }
    })
  }
}

export function changeCategoryId(categoryId) {
  return {
    type: types.changeCategoryId,
    categoryId,
  }
}

export function changeFamilyId(familyId) {
  return {
    type: types.changeFamilyId,
    familyId,
  }
}

export function rescrapeProductData(productId) {
  return productManagementApi.rescrapeProductData({ productId }).then(res => {
    if (res.status === 200) {
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Rescraping is in process. Please refresh the page',
      })
      return
    }

    const message = res?.body?.defaultFormat ? replaceTemplateValues(res.body) : 'Unhandled error'
    const title = res.status

    NotificationSystem.addNotification({
      level: 'error',
      message,
      title,
    })
  })
}
