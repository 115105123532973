import React, { useEffect, useState } from 'react'
import { DisplaySettings, Table } from '@r1/grid'
import { OfferEvent } from '@r1-webui/gowholesale-offermanagement-v1'

import { OfferHistoryGridProps } from './types'
import { offerHistoryGridHeaders } from './const'
import { getOfferHistoryGridCellRenderer } from './utils'

export const OfferHistoryGrid = ({ getOfferHistory }: OfferHistoryGridProps) => {
  const [history, setHistory] = useState<OfferEvent[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setLoading(true)
        const response = await getOfferHistory()
        setHistory(response)
      } finally {
        setLoading(false)
      }
    }

    fetchHistory()
  }, [getOfferHistory])

  const displaySettings = React.useMemo<DisplaySettings<OfferEvent>>(
    () => ({
      showCounter: false,
      columnsSettings: [
        ...Array.from(offerHistoryGridHeaders).map(([headerType, columnSettings]) => ({
          width: columnSettings.width,
          minWidth: 100,
          header: {
            content: <div>{columnSettings.header}</div>,
          },
          cell: {
            $type: 'custom' as const,
            renderer: getOfferHistoryGridCellRenderer(headerType),
          },
        })),
      ],
    }),
    [],
  )

  return <Table loading={loading} data={history} displaySettings={displaySettings} />
}
