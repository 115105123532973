import React from 'react'
import { format } from 'date-fns'
import { OfferEvent } from '@r1-webui/gowholesale-offermanagement-v1'
import { YMDh12Format } from '../../../../utils/dates/formats'
import { OfferHistoryGridHeaderType } from './types'

export const getOfferHistoryGridCellRenderer = (header: OfferHistoryGridHeaderType) => {
  switch (header) {
    case OfferHistoryGridHeaderType.Username:
      return (offerEvent: OfferEvent) => <div>{offerEvent.createdBy}</div>
    case OfferHistoryGridHeaderType.Text:
      return (offerEvent: OfferEvent) => {
        switch (offerEvent.eventType.type) {
          case 'OfferCreated': {
            const { price, quantity } = offerEvent.eventType
            return (
              <div>{`The offer created at $${price.amount}/unit for ${quantity.value} unit(s)`}</div>
            )
          }
          case 'OfferPriceChanged': {
            const { oldPrice, newPrice } = offerEvent.eventType

            const oldPriceNumeric = Number(oldPrice.amount)
            const newPriceNumeric = Number(newPrice.amount)

            if (oldPriceNumeric < newPriceNumeric) {
              return (
                <div>{`The offer price has increased from $${oldPrice.amount}/unit to $${newPrice.amount}/unit`}</div>
              )
            }

            if (oldPriceNumeric > newPriceNumeric) {
              return (
                <div>{`The offer price has lowered from $${oldPrice.amount}/unit to $${newPrice.amount}/unit`}</div>
              )
            }

            return <div>The offer price has not changed</div>
          }
          case 'OfferQuantityChanged': {
            const { oldQuantity, newQuantity } = offerEvent.eventType
            return (
              <div>{`The offer quantity changed from ${oldQuantity.value} unit(s) to ${newQuantity.value} unit(s)`}</div>
            )
          }
          case 'CounterOfferSubmitted': {
            const { price, quantity } = offerEvent.eventType
            return `The ${offerEvent.createdBy} submitted counter offer at $${price.amount}/unit${
              quantity ? ` to ${quantity.value} unit(s)` : ''
            }`
          }
          case 'OfferAccepted': {
            const { price, quantity } = offerEvent.eventType
            if (offerEvent.createdBy === 'R1') {
              return (
                <div>{`The offer automatically accepted at the price of $${price.amount}`}</div>
              )
            }
            return (
              <div>
                The {offerEvent.createdBy} accepted the offer at ${price.amount}/unit for{' '}
                {quantity.value} unit(s)
              </div>
            )
          }
          case 'OfferCancelled': {
            return <div>The offer canceled by the customer</div>
          }
          case 'OfferExpired': {
            return <div>The offer is expired</div>
          }
          case 'OfferFailedToReserve': {
            return <div>Reservation of items from the offer failed</div>
          }
          case 'OfferRejected': {
            const { price } = offerEvent.eventType
            return <div>The seller rejected the offer at ${price.amount}/unit</div>
          }
          case 'OfferCompleted': {
            return <div>Customer created the order with the offer</div>
          }
          case 'OfferPaymentError': {
            return <div>Offer cancelled due to payment error</div>
          }
          case 'CounterOfferAutoCreated': {
            const { price, quantity } = offerEvent.eventType
            return (
              <div>
                Сounter offer submitted automatically at ${price.amount}/unit to ${quantity.value}{' '}
                unit(s)
              </div>
            )
          }
          case 'CancelAcceptedOffer':
          case 'RejectAcceptedOffer':
            return <div>Offer removed from the cart by the customer</div>
          default: {
            return <div>Unknown event</div>
          }
        }
      }
    case OfferHistoryGridHeaderType.Date:
      return (offerEvent: OfferEvent) => (
        <div>{format(new Date(offerEvent.createdOn), YMDh12Format)}</div>
      )
    default:
      return () => null
  }
}
